import { Suspense, lazy } from 'react'

import { access } from 'common/session'
import Loader from 'common/widgets/Loader'
import AppLayout from 'layouts/AppLayout'
import { routes as auth } from 'pages/auth'
import NotFound from 'pages/fallbacks'
import { routes as main } from 'pages/main'
import { routes as profile } from 'pages/profile'
const CompanyDetail = lazy(() => import('pages/company-detail'))


const appRoutes = [
  {
    path: '/',
    exact: true,
    name: 'root',
    redirectTo: 'dashboard',
  },
  {
    path: '/',
    layout: AppLayout,
    routes: [
      {
        path: '/auth',
        routes: auth,
        name: 'auth',
      },
      {
        path: '/dashboard',
        routes: main,
        access: access.F_PROTECTED,
        accessRedirectTo: 'auth',
        name: 'dashboard',
      },
      {
        path: '/profile',
        routes: profile,
        access: access.F_PROTECTED,
        accessRedirectTo: 'auth',
        name: 'profile',
      },
      {
        path: '/company-detail/:id',
        access: access.F_PROTECTED,
        accessRedirectTo: 'auth',
        component: () => <Suspense fallback={<Loader isLoading />}><CompanyDetail/></Suspense>,
        name: 'company-detail',
      },
      {
        path: '/404',
        component: NotFound,
        name: '404',
      },
      {
        component: NotFound,
      },
    ],
  },
]

export default appRoutes
